import React from "react";
const About = () => {
    let navigate = React.router.useNavigate();
    const [LoginUser, setLoginUser] = React.useState(React.$cookie.load('LoginUser') ?? {})
    return (
        <>
            <React.$vant.NavBar
                title="关于我们"
                leftText=""
                onClickLeft={() => navigate(-1)}
            />
            <div className="jianjie_k">
                <h2>慧鑫医疗器械</h2>
                <p>	现在我还不知道那顿没吃饱的晚饭对我今后的人生有多大影响。人是不可以敷衍自己的。尤其是吃饭，这顿没吃饱就是没吃饱，不可能下一顿多吃点就能补偿。没吃饱的这顿饭将作为一种欠缺空在一生里，命运迟早会抓住这个薄弱环节击败我。</p>
                <p>那一天我忙了些什么现在一点也记不清了，只记得天黑时又饥又累回到宿舍，胡乱地啃了几口干馕便躺下了，原想休息一会儿出去好好吃顿饭。谁知一躺下便睡了过去，醒来时已经是第二天早晨。 </p>
                <img src="/assets/images/banner.jpg" alt="" />
                <p>我就这样给自己省了一顿饭钱。这又有什么用呢？即使今天早晨我突然暴富，腰缠千万，我也只能为自己备一顿像样点的早餐。却永远无法回到昨天下午，为那个又饿又累的自己买一盘菜一碗汤面。</p>
            </div>
            <div className="footer_jianj">
                <p>{LoginUser.beian}</p>
            </div>
        </>
    )
}

export default About;