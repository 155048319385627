import React from 'react';
const ProductIndex = () => {
    let navigate = React.router.useNavigate();
    const [keywords, setKeywords] = React.useState('');
    const [list, setList] = React.useState([]);
    let [searchParams] = React.router.useSearchParams();
    const [action, setAction] = React.useState(searchParams.get('action') ?? '')

    React.useEffect(() => {
        getProductData()
    }, [keywords])

    const getProductData = async () => {
        let result = await React.$api.productindex({
            keywords: keywords
        })
        if (result && result.code == 1) {
            setList(result.data)
        }
    }
    const toPages = (item) => {
        if (action == 'order') {
            React.$cookie.save('product', {
                id: item.id,
                name: item.name,
                rent: item.rent,
                price: item.rent_price
            })
            navigate(-1)
        } else {
            navigate(`/product/info?proid=${item.id}`)
        }
    }

    return (
        <>
            <React.$vant.NavBar
                title="产品列表"
                leftText=""
                onClickLeft={() => navigate(-1)}
            />
            <div className="sous_anniu">
                <React.$vant.Search value={keywords} onChange={setKeywords} clearable placeholder="请输入搜索关键词" />
            </div>
            <div className="left_kuangs">
                <ul>
                    {
                        list.map((item) => {
                            return (
                                <li key={item.id} onClick={() => toPages(item)}>
                                    {/* <React.router.NavLink to={`/product/info?proid=${item.id}`} > */}
                                        <img src={item.thumb_text} alt="" />
                                        <p>{item.name}</p>
                                        <span>￥{item.rent_price}</span>
                                    {/* </React.router.NavLink> */}
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </>
    )
}
export default ProductIndex;