import { POST } from '@/services/request'

export default {
    home(data = {}) {
        return POST({
            url: '/product/home',
            params: data
        })
    },
    categoryindex(data = {}) {
        return POST({
            url: '/product/categoryindex',
            params: data
        })
    },
    categoryinfo(data = {}) {
        return POST({
            url: '/product/categoryinfo',
            params: data
        })
    },
      categorycollect(data = {}) {
        return POST({
            url: '/product/collect',
            params: data
        })
    },
    productindex(data = {}) {
        return POST({
            url: '/product/index',
            params: data
        })
    },
    productinfo(data = {}) {
        return POST({
            url: '/product/info',
            params: data
        })
    },
}