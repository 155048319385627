import ProductIndex from '@/components/product/index'
import ProductInfo from '@/components/product/info'

export default [
    {
        path: 'index',
        name: 'ProductIndex',
        component: ProductIndex,
    },
    {
        path: 'info',
        name: 'ProductInfo',
        component: ProductInfo,
    },
]