import React from "react";
import { areaList } from '@vant/area-data'
const LeaseAdd = () => {
    let navigate = React.router.useNavigate();
    const [LoginUser, setLoginUser] = React.useState(React.$cookie.load('LoginUser') ?? {})
    const [region, setRegion] = React.useState(LoginUser.region)
    const [visible, setVisible] = React.useState(false)
    const [form] = React.$vant.Form.useForm()
    const [product, setProduct] = React.useState(React.$cookie.load('product') ?? {})
    // 天数，10天
    const [day, setDay] = React.useState(10)
    //10天后的日期
    const [end, SetEnd] = React.useState(new Date(new Date().getTime() + 10 * 24 * 60 * 60 * 1000))
    // 回显日期
    const [endtime, SetEndTime] = React.useState(`${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`)
    // 日历
    const [DateShow, SetDateShow] = React.useState(false)

    //不含押金
    const [prices, SetPrices] = React.useState(0)
    // 含押金
    const [rent, setRent] = React.useState(0)

    
    const onFinish = async(values) => {
        let data = {
            busid: LoginUser.id,
            proid: product.id,
            rent: values.rent,
            price: rent,
            endtime: endtime,
            address: values.address,
            province: LoginUser.province,
            city: LoginUser.city,
            district: LoginUser.district,
            card: values.card[0].file,
        }
        let result = await React.$api.leaseAdd(data)
        if (result.code == 1) {
            React.$vant.Toast.success({
                message: result.msg,
                onClose: () => {
                    navigate('/product/leaseindex')
                }
            })
        } else {
            React.$vant.Toast.fail(result.msg)
        }
       
    }

    React.useEffect(() => {
        Total()
    }, [day])

    // 计算价格
    const Total = () => {
        // 日租金
        let price = product.price ?? 0;
        // 押金
        let rent = product.rent ?? 0;

        // 不含有押金
        let prices = (parseFloat(price) * day).toFixed(2)
        SetPrices(prices)

        // 含押金
        let rents = (parseFloat(prices) + parseFloat(rent)).toFixed(2)
        setRent(rents)
    }

    const DateConfirm = (date) => {
        SetDateShow(false)
        // 更改默认时间
        SetEnd(date)
        SetEndTime(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`)
        let now = new Date()
        let time = date - now  //时间戳
        let days = Math.ceil(time / (24 * 60 * 60 * 1000))
        setDay(days)
    }


    const onConfirm = (val, item) => {
        setVisible(false)
        let regions = '';
        let [province, city, district] = item
        if (province) {
            regions += province.text + '-';
            LoginUser.province = province.value
        }
        if (city) {
            regions += city.text + '-';
            LoginUser.city = city.value
        }
        if (district) {
            regions += district.text;
            LoginUser.district = district.value
        }
        setRegion(regions)
    }
    return (
        <>
            <React.$vant.NavBar
                title="商品租赁"
                leftText=""
                onClickLeft={() => navigate(-1)}
            />

            <React.$vant.Form
                form={form}
                onFinish={onFinish}
            >
                <React.$vant.Form.Item
                    isLink
                    rules={[{ required: true, message: '请选择商品' }]}
                    name='name'
                    label='租赁商品'
                    initialValue={product.name}
                    onClick={() => navigate('/product/index?action=order')}
                >
                    <React.$vant.Input placeholder='请选择商品' readOnly />
                </React.$vant.Form.Item>
                <React.$vant.Form.Item
                    rules={[{ required: true, message: '请填写押金' }]}
                    name='rent'
                    label='押金'
                    initialValue={product.rent}
                >
                    <React.$vant.Input placeholder='请输入押金' readOnly />
                </React.$vant.Form.Item>
                <React.$vant.Form.Item
                    rules={[{ required: true, message: '请填写日供租金' }]}
                    name='price'
                    label='日供'
                    initialValue={product.price}
                >
                    <React.$vant.Input placeholder='请填写日供租金' readOnly />
                </React.$vant.Form.Item>
                <React.$vant.Form.Item
                    rules={[{ required: true, message: '请填写昵称' }]}
                    name='nickname'
                    label='昵称'
                    initialValue={LoginUser.nickname}
                >
                    <React.$vant.Input placeholder='请填写昵称' />
                </React.$vant.Form.Item>
                <React.$vant.Form.Item
                    rules={[{ required: true, message: '请填写手机号码' }]}
                    name='mobile'
                    label='手机号码'
                    initialValue={LoginUser.mobile}
                >
                    <React.$vant.Input placeholder='请填写手机号码' />
                </React.$vant.Form.Item>
                <React.$vant.Form.Item
                    isLink
                    label='地区选择'
                    onClick={() => setVisible(true)}
                >
                    <React.$vant.Input placeholder='请选择地区' readOnly value={region} />
                </React.$vant.Form.Item>
                <React.$vant.Popup position='bottom' visible={visible} onClose={() => setVisible(false)}>
                    <React.$vant.Area
                        title='请选择'
                        areaList={areaList}
                        onCancel={() => setVisible(false)}
                        onConfirm={onConfirm}
                    />
                </React.$vant.Popup>
                <React.$vant.Form.Item
                    rules={[{ required: true, message: '请填写详细地址' }]}
                    name='address'
                    label='详细地址'
                >
                    <React.$vant.Input placeholder='请填写详细地址' />
                </React.$vant.Form.Item>
                {/* 租用时间 */}
                <React.$vant.Form.Item
                    isLink
                    label='租用时间'
                    onClick={() => SetDateShow(true)}
                >
                    <React.$vant.Input placeholder='请选择租用时间' readOnly value={endtime} />
                </React.$vant.Form.Item>
                {/* 日历 */}
                <React.$vant.Calendar
                    showConfirm={false}
                    visible={DateShow}
                    onClose={() => SetDateShow(false)}
                    defaultValue={end}
                    onConfirm={DateConfirm}
                />

                <React.$vant.Form.Item
                    name='card'
                    label='上传证件'
                    rules={[{ required: true, message: '请上传证件图片' }]}
                >
                    <React.$vant.Uploader maxCount={1} />
                </React.$vant.Form.Item>

                <div style={{ height: '1rem' }}></div>
                <div className="zy_goods_foot dis_flex">
                    <div className="left">
                        <div>{day}天<b>￥<em>{prices}</em></b></div>
                        <div className="tou_d">含押金￥{rent}</div>
                    </div>
                    <p> <React.$vant.Button nativeType='submit' size="normal" type='warning' block>
                        立即申请
                    </React.$vant.Button></p>
                </div>
            </React.$vant.Form>
        </>
    )
}

export default LeaseAdd;