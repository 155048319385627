import CategoryIndex from '@/components/category/index'
import CategoryInfo from '@/components/category/info'

export default [
    {
        path: 'categoryindex',
        name: 'CategoryIndex',
        component: CategoryIndex,
    },
    {
        path: 'categoryinfo',
        name: 'CategoryInfo',
        component: CategoryInfo,
    },
]