import React from "react";
import { Card } from 'react-vant';
const Record = () =>{
    let navigate = React.router.useNavigate();
    const [LoginUser, setLoginUser] = React.useState(React.$cookie.load('LoginUser') ?? {})
     //刷新
     const [caselist, setCaselist] = React.useState([])
     const [page, setPage] = React.useState(1)
      const [finished, setFinished] = React.useState(false)

      React.useEffect(() => {
        getConsumelist()
    },[])

    const getConsumelist = async()=>{
        let result = await React.$api.record({
            busid: LoginUser['id'],
             page: page,
        })
        if(result && result.code == 1)
        {
            //刷新
            var list = [...caselist, ...result.data.caselist]
            setCaselist(list)
            setPage(page + 1)
            setTimeout(() =>{
                setFinished(false)
            }, 2000)
            
        }else {
            setFinished(true)
            React.$vant.Toast.info(result.msg)
            return false
            // React.$vant.Toast.file(result.msg)
          
        }
    };

      // 下拉刷新
      const onRefresh = () => {
        setCaselist([])
        setPage(1)
        setFinished(false)
        return new Promise(resolve => {
            setTimeout(() => {
                React.$vant.Toast.info('刷新成功')
                resolve(true)
            }, 1000)
        })
    }
    //刷新
    const load = () => {
        setFinished(true)
        getConsumelist()
    }
 

    return (
        <>
             <React.$vant.NavBar
                title="消费记录"
                leftText=""
                onClickLeft={() => navigate('/business/my')}
            />
       
         {/* 刷新 */}
         <div className="box">
                <ul>
                    <React.$vant.PullRefresh onRefresh={() => onRefresh(true)}>
                        {/* List 组件可以与 PullRefresh 组件结合使用，实现下拉刷新的效果 */}
                        <React.$vant.List finished={finished} onLoad={load} offset={5}>
                            {
                                caselist.map((item) => {
                                    return (
                                        <li key={item.id} style={{ width: "86%",margin: "auto",marginBottom: "15px" }}>
                                        <Card round style={{ backgroundColor: "rgb(249 250 255)",padding:"10px 5px" }}>
                                            <Card.Header style={{
                                                fontWeight:"lighter",
                                            }}> { item.content }</Card.Header>
                                            <Card.Body style={{ marginTop: "5px",    textAlign: "right", }}>{ item.createtime }</Card.Body>
                                        </Card>
                                        </li>
                                    )
                                })
                            }
                        </React.$vant.List>
                    </React.$vant.PullRefresh>

                </ul>
            </div>
        </>
    )
}

export default Record;