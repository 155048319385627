import React from "react";
const Recovery = () => {
    let navigate = React.router.useNavigate();
    let [searchParams] = React.router.useSearchParams();
    const [LoginUser, setLoginUser] = React.useState(React.$cookie.load('LoginUser') ?? {})
    const [leaseid, setLeaseid] = React.useState(searchParams.get('leaseid') ?? 0)
    const [info, setInfo] = React.useState({
        product: {}
    })
    const [express, setExpress] = React.useState('')
    const actions = React.useRef(null)
    const [busexpid,setBusexpid] = React.useState('')
    const [expresslist, setExpresslist] = React.useState([])

    React.useEffect(() => {
        getLeaseInfoData()
    }, [leaseid])

    const getLeaseInfoData = async () => {
        let result = await React.$api.leaseInfo({
            leaseid: leaseid,
            busid: LoginUser.id
        })
        if (result && result.code == 1) {
            setInfo(result.data);
        }

        let res = await React.$api.leaseExpresslist()
        if (res && res.code == 1) {
            setExpresslist(res.data);
        }
    }

    const [form] = React.$vant.Form.useForm()

    const onFinish = async(values) => {
        let result = await React.$api.leaseRecovery({
            leaseid: leaseid,
            busid: LoginUser.id,
            busexpid: busexpid,
            busexpcode: values.busexpcode
        })
        if (result.code == 1) {
            React.$vant.Toast.success({
                message: result.msg,
                onClose: () => {
                    navigate('/product/leaseindex')
                }
            })
        } else {
            React.$vant.Toast.fail(result.msg)
        }
    }

    const onConfirmEvent = (val, item) =>{
        setExpress(item.text)
        setBusexpid(item.value)
    }

    return (
        <>
            <React.$vant.NavBar
                title="订单详情"
                leftText=""
                onClickLeft={() => navigate('/product/leaseindex')}
            />

            <div className="zy_module_content" style={{ paddingTop: 0 }}>
                <div className="swiper-container vip_user_order">
                    <div className="swiper-slide">
                        <ul>
                            <li style={{ borderBottom: 'none' }}>
                                <div className="vip_order_goods">
                                    <h3>
                                        <a href="my_dd_xq.html">
                                            <i><img src={info.product.thumb_text} /></i>
                                            <div style={{
                                                float: 'right', marginLeft: '2.5%',
                                                width: '70%', fontSize: '.22rem', color: '#999', lineHeight: '25px'
                                            }}>
                                                <div>{info.product.name}</div>
                                                <div>
                                                    <em>押金：</em>
                                                    <em>{info.rent}</em>
                                                </div>
                                                <div>
                                                    <em>总价：</em>
                                                    <em>{info.price}</em>
                                                </div>
                                                <div>
                                                    <em>结束时间：</em>
                                                    <em>{info.endtime_text}</em>
                                                </div>
                                                <div>
                                                    <em>订单状态：</em>
                                                    <em>{info.status_text}</em>
                                                </div>
                                            </div>
                                        </a>
                                    </h3>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="lianxi_ge">
                <a href="#">联系客服</a>
            </div>

            {/* 归还商品 */}
            <React.$vant.Form
                form={form}
                onFinish={onFinish}
            >
                <React.$vant.Form.Item
                    isLink
                    rules={[{ required: true, message: '请选择快递公司名称' }]}
                    label='快递公司名称'
                    onClick={() => actions.current.open()}
                >
                    <React.$vant.Input placeholder='请选择快递公司名称'  value={express}/>
                </React.$vant.Form.Item>
                <React.$vant.Picker
                    popup={{
                        round: true,
                    }}
                    title='物流公司名称'
                    columns={expresslist}
                    ref={actions}
                    onConfirm={onConfirmEvent}
                ></React.$vant.Picker>
                <React.$vant.Form.Item
                    rules={[{ required: true, message: '请填写物流单号' }]}
                    name='busexpcode'
                    label='物流单号'
                >
                    <React.$vant.Input placeholder='请填写物流单号' />
                </React.$vant.Form.Item>

                <div className="zy_goods_foot dis_flex">
                    <div className="left">
                        <div>退还押金<b>￥<em>{info.rent}</em></b></div>
                    </div>
                    <p>
                        <React.$vant.Button nativeType='submit' type='info' block>
                            还设备
                        </React.$vant.Button>
                    </p>
                </div>
            </React.$vant.Form>
        </>
    )
}

export default Recovery;