import React from 'react';
import { FriendsO, WapHomeO, MoreO, UserO } from '@react-vant/icons'
const Footer = () => {
    let navigate = React.router.useNavigate();
    //获取链接的值
    let location = React.router.useLocation();

    const [name, setName] = React.useState('/')

    React.useEffect(() =>{
        setName(location.pathname)
    })

    const ChangeTab = (val) =>{
        setName(val)
        navigate(val)
    }

    return (
        <>
            <React.$vant.Tabbar activeColor='#f44336' inactiveColor='#000' value={name} onChange={(e) => ChangeTab(e)}>
                <React.$vant.Tabbar.Item name='/' icon={<WapHomeO  />}>首页</React.$vant.Tabbar.Item>
                <React.$vant.Tabbar.Item name='/consult/index' icon={<MoreO  />}>咨询</React.$vant.Tabbar.Item>
                <React.$vant.Tabbar.Item name='/product/categoryindex' icon={<FriendsO />}>学术</React.$vant.Tabbar.Item>
                <React.$vant.Tabbar.Item name='/business/my' icon={<UserO  />}>我的</React.$vant.Tabbar.Item>
            </React.$vant.Tabbar>
        </>
    )
}

export default Footer;