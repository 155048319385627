import React from 'react';
import Footer from '@/components/footer';
import { SettingO, OrdersO, GoldCoinO, InfoO, Close } from '@react-vant/icons'  
const My = () =>{
    //定义跳转方法
    let navigate = React.router.useNavigate();
    const [collect, setCollect] = React.useState({}) 
    const [LoginUser, setLoginUser] = React.useState(React.$cookie.load('LoginUser') ?? {})
    // console.log(LoginUser)
    const logout = () => {
            React.$vant.Dialog.confirm({
                title: '确认是否退出登陆？',
                onConfirm: () => {
                    //清除cookie内容
                    React.$cookie.remove('LoginUser')
                    //跳转
                    navigate('/business/login')
                },
            //防止报错
            }).catch(err => {});
    }
       //收藏
       const Collection = async() =>{
     
        let result = await React.$api.collection({
            busid : LoginUser.id,
        });
        if(result.code == 1)
        {
            setCollect(result.data)
        }else{
           React.$vant.Toast.fail(result.msg)
        }
      }
    //进来直接执行
     React.useEffect(() => {
        Collection()
    }, [])
    return (
        <>
            <div className="my_kuang">
                <div className="bj_img">
                    <img className="beij_s" src="/assets/images/my_beij.jpg" alt="" />
                    <div className="nimetou_gaib">
                        <div className="toux_hou">
                            <img id="Dfgrg" style={{ height: '1.1rem' }} src={LoginUser.avatar} alt="" />
                            <input id="tupian_Sc" type="file" />
                        </div>
                        <div className="mingz">
                            <h2>{LoginUser.nickname}</h2>
                        </div>
                    </div>
                </div>
                <div className="div_bx_k">
                    <div className="neir_Ef">
                        <div className="yverjif">
                            <ul>
                                <li><h2>{collect.cateid}</h2><p>收藏文章</p></li>
                                <li><a href="my_cs.html"> <h2>{collect.proid}</h2><p>收藏商品</p></a></li>
                                <li><h2>{collect.lease}</h2><p>订单数量</p></li>
                            </ul>
                        </div>
                        <div className="list_index_my">
                            <div className="fenh_ziyek">
                                <h3>更多服务 </h3>
                            </div>
                            <React.router.NavLink to="/business/profile" >
                                <React.$vant.Cell title='基本资料' icon={<SettingO color='#ffc0cb' style={{ fontSize: '2em' }} />} isLink />
                            </React.router.NavLink>
                            
                            <React.router.NavLink to="/product/leaseindex" >
                                <React.$vant.Cell title='我的订单' icon={<OrdersO color='#ffc0cb' style={{ fontSize: '2em' }} />} isLink />
                            </React.router.NavLink>

                            <React.router.NavLink to="/business/record" >
                                  <React.$vant.Cell title='消费记录' icon={<OrdersO color='#ffc0cb' style={{ fontSize: '2em' }} />} isLink />
                            </React.router.NavLink>

                            <React.router.NavLink to="/business/about" >
                                <React.$vant.Cell title='关于我们' icon={<InfoO color='#ffc0cb' style={{ fontSize: '2em' }} />} isLink />
                            </React.router.NavLink>

                            <React.$vant.Cell title='退出登陆' icon={<Close color='#ffc0cb' style={{ fontSize: '2em' }} />} isLink onClick={logout}/>
                            {/* 路由跳转
                            <React.router.NavLink to="/business/login" >
                                <React.$vant.Cell title='退出登陆' icon={<Close color='#ffc0cb' style={{ fontSize: '2em' }} />} isLink onClick={logout}/>
                            </React.router.NavLink> */}

                        </div>
                        <div style={{ height: '.9rem' }}></div>
                    </div>

                </div>
            </div>
            <div style={{ height: '.75rem' }}></div>
            
		   <Footer/>
        </>
    )
}

export default My;