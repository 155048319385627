// 默认引入当前目录的所有index.js文件
const ModulesFile = require.context('./', true, /index.js$/)

//接口集合
var ApiList = {}

ModulesFile.keys().reduce((modules, modulePath) => {
  //文件名
  const ModuleName = modulePath.replace(/^.\/(.*)\.js/,'$1')

  //不包含当前index.js 文件
  if(ModuleName !== 'index')
  {
    //请求列表
    const ModuleList = ModulesFile(modulePath)

    //合并对象 循环多次合并
    ApiList = Object.assign(ApiList,ModuleList.default)
  }

  return ApiList
}, {})

//导出接口集合
export default ApiList

