import React from 'react'

const AuthRouter = (props) => {

    const AuthLogin = () => {
        let navigate = React.router.useNavigate();
        React.useEffect(() => {
            let LoginUser = React.$cookie.load('LoginUser') ?? {}
            if (JSON.stringify(LoginUser) == '{}') {
                React.$vant.Toast.info({
                    message: '未登陆，请先去登陆',
                    onClose: () => {
                        navigate('/business/login')
                    }
                })
                
            }
        })

        return false;
    }

    return (
        <>
            {
                props.auth && <AuthLogin />
            }
            {props.component}
        </>
    )
}


export default AuthRouter;
