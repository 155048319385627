import React from "react";
const Register = () => {
    //1.变成form表单
    const [form] = React.$vant.Form.useForm();
    let navigate = React.router.useNavigate();
    //提交触发异步请求
    const onFinish = async (values) => {
        let result = await React.$api.register(values)
        // console.log(result)
        if (result.code == 1) {
            React.$vant.Toast.success({
                message: result.msg,
                // 跳转
                onClose: () => {
                    navigate('/business/login')
                }
            })
        } else {
            React.$vant.Toast.fail(result.msg)
        }
    }
    return (
        <>
            <link rel="stylesheet" type="text/css" href="/assets/css/util.css"></link>
            <link rel="stylesheet" type="text/css" href="/assets/css/main.css"></link>
            <div className="limiter">
                <div className="container-login100" style={{ backgroundImage: `url('/assets/images/img-01.jpg')` }}>
                    <div className="wrap-login100 p-t-190 p-b-30">
                        {/* 2.变成form表单 */}
                        <React.$vant.Form className="login100-form validate-form"
                            form={form}
                            onFinish={onFinish}
                        >
                            <div className="login100-form-avatar">
                                <img src="/assets/images/avatar.png" alt="AVATAR" />
                            </div>

                            <span className="login100-form-title p-t-20 p-b-45">Hello</span>

                            <React.$vant.Form.Item
                                // 校验
                                rules={[{ required: true, message: '请填写手机号码' }, { pattern: /^1[3456789]\d{9}$/, message: '请填写正确的手机号码' }]}
                                // 提交获取的name值
                                name='mobile'
                                label='手机号码'
                                
                            >
                                <React.$vant.Input placeholder='请输入手机号码' />
                            </React.$vant.Form.Item>

                            <React.$vant.Form.Item
                                rules={[{ required: true, message: '请填写密码' }, {
                                    type: 'string',
                                    min: 4,
                                    max: 16,
                                    message: '密码最少4位,最多16位',
                                }]}
                                name='password'
                                label='密码'
                            >
                                <React.$vant.Input placeholder='请输入密码' type='password' />
                            </React.$vant.Form.Item>

                            <div className="container-login100-form-btn p-t-10">
                                {/* 提交事件 */}
                                <React.$vant.Button type='warning' round nativeType='submit' block>注 册</React.$vant.Button>
                            </div>

                            <div className="text-center w-full p-t-25 p-b-230">
                            </div>

                            <div className="text-center w-full">
                                <React.router.NavLink to="/business/login" className="txt1">
                                    立即登陆
                                    <i className="fa fa-long-arrow-right"></i>
                                </React.router.NavLink>
                            </div>
                        </React.$vant.Form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Register;