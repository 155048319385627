import React from 'react';
import { Star, StarO } from '@react-vant/icons'
const CategoryInfo = () => {
    let [searchParams] = React.router.useSearchParams();
    let navigate = React.router.useNavigate();
    const [cateid, setCateid] = React.useState(searchParams.get('cateid') ?? 0)
    const [info, setInfo] = React.useState({})
    const [next, setNext] = React.useState({})
    const [prev, setPrev] = React.useState({})
    const [LoginUser, setLoginUser] = React.useState(React.$cookie.load('LoginUser') ?? {})

    React.useEffect(() => {
        getCategoryInfoData()
    }, [cateid])

    const getCategoryInfoData = async () => {
        let result = await React.$api.categoryinfo({
            cateid: cateid,
            busid: LoginUser.id
        })
        if (result && result.code == 1) {
            setInfo(result.data.info);
            setNext(result.data.next);
            setPrev(result.data.prev);
        }
    }

    const Next = () => {
        if (next) {
            return (
                <p >下一篇： <React.router.NavLink to={`/product/categoryinfo?cateid=${next.id}`} ><span onClick={() => setCateid(next.id)}>{next.name}</span></React.router.NavLink></p>
            )
        } else {
            return (
                <p>下一篇：暂无文章</p>
            )
        }
    }

    const Prev = () => {
        if (prev) {
            return (
                <p>上一篇： <React.router.NavLink to={`/product/categoryinfo?cateid=${prev.id}`} ><span onClick={() => setCateid(prev.id)}>{prev.name}</span></React.router.NavLink></p>
            )
        } else {
            return (
                <p>上一篇：暂无文章</p>
            )
        }
    }

    const Collection = () => {
        if (LoginUser.id) {
            if (info.collection) {
                return (
                    <a onClick={ChangeCollect}> <Star fontSize="1.4em" />已收藏</a>
                )
            } else {
                return (
                    <a onClick={ChangeCollect}> <StarO fontSize="1.4em" />未收藏</a>
                )
            }

        } else {
            return (
                <a onClick={goPages}> <StarO fontSize="1.4em" />未收藏</a>
            )
        }
    }

    const goPages = () => {
        React.$vant.Toast.success({
            message: '未登陆，请先登陆',
            onClose: () => {
                navigate('/business/login')
            }
        })
    }
    // 收藏方法
    const ChangeCollect = async()=>{
        let result = await React.$api.categorycollect({
            cateid: cateid,
            busid: LoginUser.id
        })
        if (result && result.code == 1) {
            React.$vant.Toast.success({
                message: result.msg
            })
            getCategoryInfoData()
        }else {
            React.$vant.Toast.fail(result.msg)
        }
    }
    return (
        <>
            <React.$vant.NavBar
                title="学术详情"
                leftText=""
                onClickLeft={() => navigate('/product/categoryindex')}
            />
            <div className="xs_Xiangq">
                <div className="bt">
                    <h2>{info.name} </h2>
                    <div className="text">
                        <span></span>
                        <span>{info.createtime}</span>
                    </div>
                </div>
                <div className="wom_de">
                    <p>{info.description}</p>
                </div>
                <div className="niming_sq niming_sq_xs_xq">
                    <Collection />
                </div>
            </div>

            <div className="rem2"></div>
            <div className="shangxia_piab">
                <Prev />
                <Next />
            </div>
        </>
    )
}

export default CategoryInfo;