import React from "react";
import { Fire, FireO } from '@react-vant/icons'
const LeaseInfo = () => {
    let navigate = React.router.useNavigate();
    let [searchParams] = React.router.useSearchParams();
    const [LoginUser, setLoginUser] = React.useState(React.$cookie.load('LoginUser') ?? {})
    const [leaseid, setLeaseid] = React.useState(searchParams.get('leaseid') ?? 0)
    const [info, setInfo] = React.useState({
        product: {}
    })

    React.useEffect(() => {
        getLeaseInfoData()
    }, [leaseid])

    const getLeaseInfoData = async () => {
        let result = await React.$api.leaseInfo({
            leaseid: leaseid,
            busid: LoginUser.id
        })
        if (result && result.code == 1) {
            setInfo(result.data);
        }
    }

    const Expressquery = () => {
        if (info.expcode) {
            return (
                <>
                    <li>物流公司名称：<span>{info.express_text}</span></li>
                    <li>物流单号：<span>{info.expcode}</span></li>
                </>
            )
        }
    }

    const Comment = () => {
        if (info.rate) {
            return (
                <>
                    <li>评分： <React.$vant.Rate
                        icon={<Fire />}
                        voidIcon={<FireO />}
                        value={info.rate}
                        readOnly
                    /></li>
                    <li>评价内容： <React.$vant.Input.TextArea readOnly
                        defaultValue={info.comment} />
                    </li>
                </>
            )
        }
    }

    const Gender = () => {
        if (LoginUser.gender == '0') {
            return (
                <li>性别：<span>保密</span></li>
            )
        } else if (LoginUser.gender == '1') {
            return (
                <li>性别：<span>男</span></li>
            )
        } else if (LoginUser.gender == '2') {
            return (
                <li>性别：<span>女</span></li>
            )
        }
    }

    return (
        <>
            <React.$vant.NavBar
                title="订单详情"
                leftText=""
                onClickLeft={() => navigate('/product/leaseindex')}
            />

            <div className="zy_module_content" style={{ paddingTop: 0 }}>
                <div className="swiper-container vip_user_order">
                    <div className="swiper-slide">
                        <ul>
                            <li style={{ borderBottom: 'none' }}>
                                <div className="vip_order_goods">
                                    <h3>
                                        <a href="my_dd_xq.html">
                                            <i><img src={info.product.thumb_text} /></i>
                                            <div style={{
                                                float: 'right', marginLeft: '2.5%',
                                                width: '70%', fontSize: '.22rem', color: '#999', lineHeight: '25px'
                                            }}>
                                                <div>{info.product.name}</div>
                                                <div>
                                                    <em>押金：</em>
                                                    <em>{info.rent}</em>
                                                </div>
                                                <div>
                                                    <em>总价：</em>
                                                    <em>{info.price}</em>
                                                </div>
                                                <div>
                                                    <em>结束时间：</em>
                                                    <em>{info.endtime_text}</em>
                                                </div>
                                                <div>
                                                    <em>订单状态：</em>
                                                    <em>{info.status_text}</em>
                                                </div>
                                            </div>
                                        </a>
                                    </h3>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="lianxi_ge">
                <a href="#">联系客服</a>
            </div>
            <div className="rem2"></div>
            <div className="xiangq_xiaox">
                <ul>
                    <li>联系人：<span>{LoginUser.nickname}</span></li>
                    <Gender/>
                    <li>联系电话：<span>{LoginUser.mobile}</span></li>
                    <li>下单时间：<span>{info.createtime}</span></li>
                    <li className="no">支付方式：<span>在线支付</span></li>
                    {/* 物流信息，评论 */}
                    <Expressquery />
                    <Comment />
                </ul>
            </div>
            <div className="rem2"></div>
            <div className="nimen_er">
                <p>证件照片</p>
                <div style={{ width: '100%', height: '150px' }}>
                    <img src={info.card_text} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </div>
            </div>
            <div className="rem2"></div>
            <div className="xq_money">
                <p>押金<span className="color">￥{info.rent}</span></p>
                <p>租金<span>+{info.price - info.product.rent_price}</span></p>
                <div>
                    <p><span>合计：<b className="color">￥{info.price}</b></span></p>
                </div>
            </div>
        </>
    )
}
export default LeaseInfo;