import React from 'react';
import { Star,ShopO, StarO} from '@react-vant/icons'
const ProductInfo = () => {
    let [searchParams] = React.router.useSearchParams();
    let navigate = React.router.useNavigate();
    const [proid, setProid] = React.useState(searchParams.get('proid') ?? 0)
    const [info, setInfo] = React.useState({
        thumb_list: ['https://img.yzcdn.cn/vant/cat.jpeg']
    })
    const [LoginUser, setLoginUser] = React.useState(React.$cookie.load('LoginUser') ?? {})

    React.useEffect(() => {
        getProductInfoData()
    }, [proid])

    const getProductInfoData = async () => {
        let result = await React.$api.productinfo({
            proid: proid,
            busid: LoginUser.id
        })
        if (result && result.code == 1) {
            setInfo(result.data);
        }
    }
 //渲染收藏
const Collection = () => {
    if(LoginUser.id){
        if(info.collection)
        {
            return(
                <a onClick={ChangeCollect}>
                   <React.$vant.ActionBar.Icon icon={<Star color='#f9839d' />} text='已收藏' />
                </a>
            )
        }else{
            return(
                <a onClick={ChangeCollect}>
                  <React.$vant.ActionBar.Icon icon={<StarO color='#f9839d' />} text='收藏' />
               </a>
                
            )
        }
    }else{
        return(
             <a onClick={ChangeCollect}>
                  <React.$vant.ActionBar.Icon icon={<StarO color='#f9839d' />} text='未收藏' />
               </a>
            
        )
    }
}

 // 收藏方法
 const ChangeCollect = async()=>{
    let result = await React.$api.categorycollect({
        proid: proid,
        busid: LoginUser.id
    })
    if (result && result.code == 1) {
        React.$vant.Toast.success({
            message: result.msg
        })
        getProductInfoData()
    }else {
        React.$vant.Toast.fail(result.msg)
    }
}

//立刻租赁
const toPages = (info) =>{
    React.$cookie.save('product', {
        id: info.id,
        name: info.name,
        rent: info.rent,
        price: info.rent_price
    })
    navigate(`/product/leaseAdd`)
}
    return (
        <>
            <React.$vant.NavBar
                title="产品详情"
                leftText=""
                onClickLeft={() => navigate(-1)}
            />
            <div className="banner_shouy">
                <React.$vant.Swiper>
                    {info.thumb_list.map((item, index) => (
                        <React.$vant.Swiper.Item key={index}>
                            <React.$vant.Image lazyload src={item} height='230' fit="cover" />
                        </React.$vant.Swiper.Item>
                    ))}
                </React.$vant.Swiper>
            </div>
            <div className="top_title">
                <h2>{info.name}</h2>
                <div className="money">
                    <h3>￥{info.rent_price}</h3>
                    <p>押金:￥{info.rent}</p>
                </div>
                <div className="youhuiq common">
                    <div className="text">
                        <h2>保障</h2>
                        <div className="tb">
                            <div className="tis_fuwu">
                                <ul>
                                    <li className="no">保障</li>
                                    <li>保障</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="youhuiq common common_no">
                    <div className="text">
                        <h2>配送</h2>
                        <div className="tb">
                            送货上门
                        </div>
                    </div>
                </div>
            </div>
            <div className="rem2"></div>
            <div className="list_index_shez" style={{ marginTop: '1px' }}>
                {/* 其中包含原始的 HTML 字符串。此属性将会覆盖 DOM 节点的 innerHTML 属性，并在内部显示传递的 HTML 内容。 */}
                <div className="my_dind" dangerouslySetInnerHTML={{ __html: info.content }}>
                </div>
            </div>
            <div style={{ height: '1rem' }}></div>

            <React.$vant.ActionBar>
                <React.$vant.ActionBar.Icon onClick={() => navigate('/')} icon={<ShopO color='#f9839d' />} text='首页' />
                 <Collection />
                <React.$vant.ActionBar.Button onClick={() => toPages(info)} color='#f9839d' type='danger' text='立即租赁' />
            </React.$vant.ActionBar>
        </>
    )
}

export default ProductInfo;