import React from 'react';
import { BrowserRouter } from "react-router-dom";
import RouterList from '@/routers/index'

function App() {

  return (
    <BrowserRouter>
      <RouterList />
    </BrowserRouter>
  );
}

export default App;