// 默认引入当前目录的所有js文件
const ModulesFile = require.context("./", false, /.js$/);

var ApiList = {}

ModulesFile.keys().reduce((modules, modulePath) => {
  const ModuleName = modulePath.replace(/^.\/(.*)\.js/,'$1')

  if(ModuleName !== 'index')
  {
    const ModuleList = ModulesFile(modulePath)

    ApiList = Object.assign(ApiList,ModuleList.default)
  }

  return ApiList
}, {})

//导出接口集合
export default ApiList

