import React from 'react';
import Footer from '@/components/footer'
const CategoryIndex = () => {
    const [suggestlist, setSuggestlist] = React.useState([{'id':'0'}])
    const [caselist, setCaselist] = React.useState([])
    const [page, setPage] = React.useState(1)
    const [finished, setFinished] = React.useState(false)
    const getCategoryData = async () => {
        let result = await React.$api.categoryindex({
            page: page
        })
        if (result) {
            if (result.code == 1) {
                var list = [...caselist, ...result.data.caselist]
                setSuggestlist(result.data.suggestlist)
                setCaselist(list)
                setPage(page + 1)
                setTimeout(() =>{
                    setFinished(false)
                }, 2000)
            } else {
                setFinished(true)
                React.$vant.Toast.info(result.msg)
                return false
            }
        }
    }


    // 下拉刷新
    const onRefresh = () => {
        setCaselist([])
        setPage(1)
        setFinished(false)
        return new Promise(resolve => {
            setTimeout(() => {
                React.$vant.Toast.info('刷新成功')
                resolve(true)
            }, 1000)
        })
    }

    const load = () => {
        setFinished(true)
        getCategoryData()
    }

    return (
        <>
            <div className="banner_shouy" height='230'>
                <React.$vant.Swiper>
                    {suggestlist.map((item) => (
                        <React.$vant.Swiper.Item key={item.id}>
                            <React.$vant.Image lazyload src={item.image_text} height='230' fit="cover" />
                        </React.$vant.Swiper.Item>
                    ))}
                      
                </React.$vant.Swiper>
            </div>
            <div className="lest_xs" style={{marginTop:"10px",}}>
                <ul>
                    <React.$vant.PullRefresh onRefresh={() => onRefresh(true)}>
                        {/* List 组件可以与 PullRefresh 组件结合使用，实现下拉刷新的效果 */}
                        <React.$vant.List finished={finished} onLoad={load} offset={5}>
                            {
                                caselist.map((item) => {
                                    return (
                                        <li key={item.id}>
                                            <React.router.NavLink to={`/product/categoryinfo?cateid=${item.id}`}>
                                                <img src={item.image_text} alt="" />
                                                <p>{item.name}</p>
                                                <span>{item.createtime}</span>
                                            </React.router.NavLink>
                                        </li>
                                    )
                                })
                            }
                        </React.$vant.List>
                    </React.$vant.PullRefresh>

                </ul>
            </div>
            <div style={{ height: '.75rem' }}></div>
            <Footer />
        </>
    )
}

export default CategoryIndex;