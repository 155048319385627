import React from "react";
import { Card } from 'react-vant';

const Logistics = () => {
  let navigate = React.router.useNavigate();
  //链接的值
  let [searchParams] = React.router.useSearchParams();
  const [leaseid, setLeaseid] = React.useState(
    searchParams.get("leaseid") ?? 0
  );


  const [expresslist, setExpresslist] = React.useState([]);
  const [businessinfo, setBusinessinfo] = React.useState({});
  React.useEffect(() => {
    getExpress();
  }, []);
  const getExpress = async () => {
    let result = await React.$api.leaseRecord({
      leaseid: leaseid,
    });
    if (result.code == 1) {
        // console.log(result.data.business)
      setExpresslist(result.data.express.data);
      setBusinessinfo(result.data.business);
    }
  };
  return (
    <>
      <React.$vant.NavBar
        title="物流详情"
        leftText=""
        onClickLeft={() => navigate(-1)}
      />
      <React.$vant.Image
        width="100%"
        height="250px"
        object-fit="over"
        src={businessinfo.avatar_text}
      />
      <Card round style={{ backgroundColor: "rgb(240 240 240)",padding:"8px 8px",margin: "auto",margin: "15px" }}>
        <Card.Header> {businessinfo.nickname}</Card.Header>
        <Card.Body style={{ fontSize:"16px"}}> {businessinfo.mobile}</Card.Body>
        <Card.Body style={{ fontSize:"16px",marginTop: "5px"}}> {businessinfo.region_text}</Card.Body>
      </Card>
      <React.$vant.Typography.Text>
        {expresslist.map((item) => (
          <>
            <React.$vant.Steps direction="vertical" active={0} key={item.id}>
              <React.$vant.Steps.Item>
                <h3>{item.status}</h3>
                <p>{item.time}</p>
              </React.$vant.Steps.Item>
              <React.$vant.Steps.Item>
                <h3>【城市】物流状态更新</h3>
                <p>2024-05-20 10:00</p>
              </React.$vant.Steps.Item>
            </React.$vant.Steps>
          </>
        ))}
      </React.$vant.Typography.Text>
    </>
  );
};

export default Logistics;