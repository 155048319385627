//引入封装的请求服务
import { POST,UPLOAD } from '@/services/request'

export default {
    login(data = {}) {
        return POST({
            url: "business/login",
            params: data
        })
    },
    register(data = {}) {
        return POST({
            url: "business/register",
            params: data
        })
    },
    profile(data = {}) {
        return UPLOAD({
            url: "business/profile",
            params: data
        })
    },
    record(data = {}) {
        return POST({
            url: "business/record",
            params: data
        })
    },
    collection(data = {}) {
        return POST({
            url: "business/collection",
            params: data
        })
    },
  
}