import React from 'react';
import Footer from '@/components/footer'
const Home = () => {
    const [suggestlist, setSuggestlist] = React.useState([{'id':'0'}])
    const [productlist, setProductlist] = React.useState([{'id':'0'}])
    const [caselist, setCaselist] = React.useState([])
   

    React.useEffect(() => {
        getHomeData();
    }, [])

    const getHomeData = async () => {
        let result = await React.$api.home()
        if (result && result.code == 1) {
            setSuggestlist(result.data.suggestlist)
            setProductlist(result.data.productlist)
            setCaselist(result.data.caselist)
        }
    }

    return (
        <>
            <div className="banner_shouy">
                <React.$vant.Swiper>
                    {suggestlist.map((item) => (
                        <React.$vant.Swiper.Item key={item.id}>
                            <React.$vant.Image lazyload src={item.image_text} height='230' fit="cover" />
                        </React.$vant.Swiper.Item>
                    ))}
                </React.$vant.Swiper>
            </div>
            <div className="shouye_kuanj">
                <React.$vant.Grid>
                <React.$vant.Grid.Item>
                        <div className="swiper-slide"><React.router.NavLink to="/product/leaseAdd" ><img src="/assets/images/kj.png" alt="" /> <p>产品租赁</p></React.router.NavLink></div>
                    </React.$vant.Grid.Item>
                    <React.$vant.Grid.Item>
                        <div className="swiper-slide"><React.router.NavLink to="/product/leaseindex" ><img src="/assets/images/kj1.png" alt="" /> <p>我要归还</p></React.router.NavLink></div>
                    </React.$vant.Grid.Item>
                    <React.$vant.Grid.Item>
                        <div className="swiper-slide"><React.router.NavLink to="/product/index" ><img src="/assets/images/kj2.png" alt="" /> <p>商品大全</p></React.router.NavLink></div>
                    </React.$vant.Grid.Item>
                    <React.$vant.Grid.Item>
                        <div className="swiper-slide"><React.router.NavLink to="/business/about" ><img src="/assets/images/kj3.png" alt="" /> <p>关于我们</p></React.router.NavLink></div>
                    </React.$vant.Grid.Item>
                </React.$vant.Grid>
            </div>
            <div className="chan_p_center">
                <div className="shouye_biaot_k">
                    <a href="list.html">
                        <h2>产品中心</h2>
                        <p>Product Center</p>
                        <img src="/assets/images/jiant.png" alt="" />
                    </a>
                </div>
                <div className="chanp_k_sy">
                    <div className="sjpiue_chanp_list">
                        <React.$vant.Swiper>
                            {productlist.map((item) => (
                                <React.$vant.Swiper.Item key={item.id}>
                                    <div className="swiper-slide"><React.router.NavLink to={`/product/info?proid=${item.id}`} className="a_blok"><img src={item.thumb_text} alt="" /> <span>查看详情</span> </React.router.NavLink></div>
                                </React.$vant.Swiper.Item>
                            ))}
                        </React.$vant.Swiper>
                    </div>
                </div>
                <div className="lest_zx">
                    <ul>
                        <li>
                            <a href="#">
                                <img src="/assets/images/fangh.jpg" alt="" />
                                <p>在线咨询</p>
                            </a>
                        </li>
                        <li>
                            <a href="zn.html">
                                <img src="/assets/images/fangh1.jpg" alt="" />
                                <p>使用指南</p>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="shouye_biaot_k">
                    <a href="anli.html">
                        <h2>经典案例</h2>
                        <p>Classic case</p>
                        <img src="/assets/images/jiant.png" alt="" />
                    </a>
                </div>

                <div className="list_color" >
                    <ul>
                        {
                            caselist.map((item) => {
                                return (
                                    <li key={item.id}>
                                        <React.router.NavLink to={`/product/categoryinfo?cateid=${item.id}`} >
                                            <img src={item.image_text} alt="" />
                                            <div className="right">
                                                <p>{item.name}</p>
                                                <span>{item.createtime}</span>
                                            </div>
                                        </React.router.NavLink>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div >
            <div style={{ height: '.75rem' }}></div>
            <Footer />
        </>
    )
}

export default Home;