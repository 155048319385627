import { Outlet } from 'react-router-dom'
// 默认引入当前目录的所有js文件
const ModulesFile = require.context("./", false, /.js$/);

var RouterMap = []

ModulesFile.keys().reduce((modules, modulePath) => {

    const ModuleName = modulePath.replace(/^.\/(.*)\.js/, '$1')

    if (ModuleName !== 'index') {
        const ModuleList = ModulesFile(modulePath)

        //追加到数组
        RouterMap.push(...ModuleList.default)
    }

    return RouterMap
}, {})

//默认父组件 可以自己定义
const layout = () => {
    return (
        <>
            <Outlet />
        </>
    )
}

export default [
    {
        path: '/consult',
        component: layout,
        children: RouterMap
    }
]


