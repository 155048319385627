import LeaseAdd from '@/components/lease/add'
import LeaseIndex from '@/components/lease/index'
import LeaseInfo from '@/components/lease/info'
import Recovery from '@/components/lease/recovery'
import Comment from '@/components/lease/comment'
import Logistics from '@/components/lease/logistics'
export default [
    {
        path: 'leaseAdd',
        name: 'LeaseAdd',
        component: LeaseAdd,
        auth: true,
    },
    {
        path: 'leaseindex',
        name: 'LeaseIndex',
        component: LeaseIndex,
        auth: true,
    },
    {
        path: 'leaseinfo',
        name: 'LeaseInfo',
        component: LeaseInfo,
        auth: true,
    },
    {
        path: 'recovery',
        name: 'Recovery',
        component: Recovery,
        auth: true,
    },
    {
        path: 'comment',
        name: 'Comment',
        component: Comment,
        auth: true,
    },
    {
        path:'logistics',
        name:'Logistics',
        component: Logistics,
        auth: true,
    }
]