import Login from '@/components/business/login'
import My from '@/components/business/my'
import Register from '@/components/business/register'
import Profile from '@/components/business/profile'
import Record from '@/components/business/record'
import About from '@/components/business/about'

export default [
    {
        path: 'login',
        name: 'Login',
        component: Login,
    },
    {
        path: 'register',
        name: 'Register',
        component: Register,
    },
    {
        path: 'my',
        name: 'My',
        component: My,
        auth: true, //校验用户是否登陆
    },
    {
        path: 'profile',
        name: 'Profile',
        component: Profile,
        auth: true,
    },
    {
        path: 'record',
        name: 'Record',
        component: Record,
        auth: true,
    },
    {
        path: 'about',
        name: 'About',
        component: About,
    },
]