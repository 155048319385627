import React from "react";
import { Fire, FireO } from '@react-vant/icons'
const Comment = () => {
    let navigate = React.router.useNavigate();
    let [searchParams] = React.router.useSearchParams();
    const [LoginUser, setLoginUser] = React.useState(React.$cookie.load('LoginUser') ?? {})
    const [leaseid, setLeaseid] = React.useState(searchParams.get('leaseid') ?? 0)
    const [info, setInfo] = React.useState({
        product: {}
    })

    React.useEffect(() => {
        getLeaseInfoData()
    }, [leaseid])

    const getLeaseInfoData = async () => {
        let result = await React.$api.leaseInfo({
            leaseid: leaseid,
            busid: LoginUser.id
        })
        if (result && result.code == 1) {
            setInfo(result.data);
        }
    }

    const [form] = React.$vant.Form.useForm()

    const onFinish = async(values) => {
        let result = await React.$api.leaseComment({
            leaseid: leaseid,
            busid: LoginUser.id,
            rate: values.rate,
            comment: values.comment
        })
        if (result.code == 1) {
            React.$vant.Toast.success({
                message: result.msg,
                onClose: () => {
                    navigate('/product/leaseindex')
                }
            })
        } else {
            React.$vant.Toast.fail(result.msg)
        }
    }

    return (
        <>
            <React.$vant.NavBar
                title="订单评价"
                leftText=""
                onClickLeft={() => navigate('/product/leaseindex')}
            />

            <div className="zy_module_content" style={{ paddingTop: 0 }}>
                <div className="swiper-container vip_user_order">
                    <div className="swiper-slide">
                        <ul>
                            <li style={{ borderBottom: 'none' }}>
                                <div className="vip_order_goods">
                                    <h3>
                                        <a href="my_dd_xq.html">
                                            <i><img src={info.product.thumb_text} /></i>
                                            <div style={{
                                                float: 'right', marginLeft: '2.5%',
                                                width: '70%', fontSize: '.22rem', color: '#999', lineHeight: '25px'
                                            }}>
                                                <div>{info.product.name}</div>
                                                <div>
                                                    <em>押金：</em>
                                                    <em>{info.rent}</em>
                                                </div>
                                                <div>
                                                    <em>总价：</em>
                                                    <em>{info.price}</em>
                                                </div>
                                                <div>
                                                    <em>结束时间：</em>
                                                    <em>{info.endtime_text}</em>
                                                </div>
                                                <div>
                                                    <em>订单状态：</em>
                                                    <em>{info.status_text}</em>
                                                </div>
                                            </div>
                                        </a>
                                    </h3>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="lianxi_ge">
                <a href="#">联系客服</a>
            </div>

            <React.$vant.Form
                form={form}
                onFinish={onFinish}
                footer={
                    <div style={{ margin: '16px 16px 0' }}>
                        <React.$vant.Button round nativeType='submit' type='info' block>
                        提交
                        </React.$vant.Button>
                    </div>
                }
            >
                <React.$vant.Form.Item
                    rules={[{ required: true, message: '请填写用户名' }]}
                    name='rate'
                    label='评分'
                >
                    <React.$vant.Rate icon={<Fire />}  voidIcon={<FireO />}/>
                </React.$vant.Form.Item>
                <React.$vant.Form.Item
                    rules={[{ required: true, message: '请填写评价内容' }]}
                    name='comment'
                    label='评价内容'
                >
                    <React.$vant.Input.TextArea  maxLength={120} showWordLimit placeholder='请输入评价内容' />
                </React.$vant.Form.Item>
            </React.$vant.Form>
        </>
    )
}

export default Comment;