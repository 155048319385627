import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// 引入全局接口文件
import api from '@/api/index'
// 引入全局路由
import * as router from 'react-router-dom'

// 引入全局UI
import * as vant from 'react-vant'

// 引入全局cookie
import cookie from 'react-cookies'

// 定义全局接口
React.$api = api;

// 全局路由
React.router = router;

// 定义全局UI
React.$vant = vant;

// 定义全局cookie
React.$cookie = cookie;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
