import React from "react";
import { areaList } from '@vant/area-data'
const Profile = () => {
    let navigate = React.router.useNavigate();
    const [LoginUser, setLoginUser] = React.useState(React.$cookie.load('LoginUser') ?? {})
    const [visible, setVisible] = React.useState(false)
    const [form] = React.$vant.Form.useForm()
    const [region, setRegion] = React.useState(LoginUser.region)

    const onFinish = async (values) => {
        let data = {
            id: LoginUser.id,
            nickname: values.nickname,
            email: values.email,
            gender: values.gender,
            province: LoginUser.province,
            city: LoginUser.city,
            district: LoginUser.district
        }

        // 判断是否修改密码
        if (values.password) {
            data.password = values.password
        }

        // 判断是否修改头像
        if (values.avatar.length > 0 && values.avatar[0].file) {
            data.avatar = values.avatar[0].file
        }
        let result = await React.$api.profile(data)
        if (result.code == 1) {
            React.$cookie.save('LoginUser', result.data)
            React.$vant.Toast.success({
                message: result.msg,
                onClose: () => {
                    navigate('/business/my')
                }
            })
        } else {
            React.$vant.Toast.fail(result.msg)
        }
    }

    const onConfirm = (val, item) => {
        setVisible(false)
        let regions = '';
        let [province, city, district] = item
        if (province) {
            regions += province.text + '-';
            LoginUser.province = province.value
        }
        if (city) {
            regions += city.text + '-';
            LoginUser.city = city.value
        }
        if (district) {
            regions += district.text;
            LoginUser.district = district.value
        }
        setRegion(regions)
    }

    return (
        <>
            <React.$vant.NavBar
                title="基本资料"
                leftText=""
                onClickLeft={() => navigate('/business/my')}
            />
            <React.$vant.Form
                form={form}
                onFinish={onFinish}
                footer={
                    <div style={{ margin: '16px 16px 0' }}>
                        <React.$vant.Button round nativeType='submit' type='info' block>
                            提交
                        </React.$vant.Button>
                    </div>
                }
            >
                <React.$vant.Form.Item
                    name='mobile'
                    label='手机号码'
                    initialValue={LoginUser.mobile}
                >
                    <React.$vant.Input placeholder='请输入用户名' readOnly />
                </React.$vant.Form.Item>
                <React.$vant.Form.Item
                    rules={[{ required: true, message: '请填写密码' }]}
                    name='nickname'
                    label='昵称'
                    initialValue={LoginUser.nickname}
                >
                    <React.$vant.Input placeholder='请输入密码' />
                </React.$vant.Form.Item>
                <React.$vant.Form.Item
                    name='email'
                    label='邮箱'
                    initialValue={LoginUser.email ?? ''}
                >
                    <React.$vant.Input placeholder='请输入用户名' />
                </React.$vant.Form.Item>
                <React.$vant.Form.Item
                    name='password'
                    label='密码'
                >
                    <React.$vant.Input placeholder='请输入用户名' type='password' />
                </React.$vant.Form.Item>
                <React.$vant.Form.Item name='gender' label='性别' initialValue={LoginUser.gender}>
                    <React.$vant.Radio.Group direction='horizontal'>
                        <React.$vant.Radio name='0'>保密</React.$vant.Radio>
                        <React.$vant.Radio name='1'>男</React.$vant.Radio>
                        <React.$vant.Radio name='2'>女</React.$vant.Radio>
                    </React.$vant.Radio.Group>
                </React.$vant.Form.Item>
                <React.$vant.Form.Item
                    isLink
                    label='地区选择'
                    onClick={() => setVisible(true)}
                >
                    <React.$vant.Input placeholder='请选择地区' readOnly value={region} />
                </React.$vant.Form.Item>
                <React.$vant.Popup position='bottom' visible={visible} onClose={() => setVisible(false)}>
                    <React.$vant.Area
                        title='请选择'
                        areaList={areaList}
                        onCancel={() => setVisible(false)}
                        onConfirm={onConfirm}
                    />
                </React.$vant.Popup>
                <React.$vant.Form.Item
                    name='avatar'
                    label='头像'
                    initialValue={[
                        {
                            url: LoginUser.avatar,
                        },
                    ]}
                >
                    <React.$vant.Uploader maxCount={1} />
                </React.$vant.Form.Item>
            </React.$vant.Form>
        </>
    )
}

export default Profile;