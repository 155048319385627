import React from "react";
const LeaseIndex = () => {
    let navigate = React.router.useNavigate();
    const [LoginUser, setLoginUser] = React.useState(React.$cookie.load('LoginUser') ?? {})
    const statuslist = [
        {
            id: '',
            name: '全部'
        },
        {
            id: '1',
            name: '已下单'
        },
        {
            id: '2',
            name: '已发货'
        },
        {
            id: '3',
            name: '已收货'
        },
        {
            id: '4',
            name: '已归还'
        },
        {
            id: '5',
            name: '待评价'
        },
        {
            id: '6',
            name: '已完结'
        }
    ];
    const [status, setStatus] = React.useState('')
    const [list, setList] = React.useState([])

    React.useEffect(() => {
        getLeaseIndexData();
    }, [status])

    const getLeaseIndexData = async () => {
        let result = await React.$api.leaseIndex({
            busid: LoginUser.id,
            status: status
        })

        if (result && result.code == 1) {
            setList(result.data)
        }
    }

    const Btns = (val) => {
        let item = val.item

        if (item.status == '2') {
            return (
                <React.$vant.Button size="mini" type="info" round plain onClick={() => confirm(item)}>
                    确认收货
                </React.$vant.Button>
            )
        } else if (item.status == '3') {
            return (
                <React.$vant.Button size="mini" type="warning" round plain onClick={() => navigate(`/product/recovery?leaseid=${item.id}`)}>
                    归还商品
                </React.$vant.Button>
            )
        } else if (item.status == '5') {
            return (
                <React.$vant.Button size="mini" type="danger" round plain onClick={() => navigate(`/product/comment?leaseid=${item.id}`)}>
                    订单评价
                </React.$vant.Button>
            )
        }else if(item.status == '6')
        {
            return(
                <React.$vant.Button size="mini" type="danger" round plain onClick={() => navigate(`/product/logistics?leaseid=${item.id}`)}>
                 物流详情
            </React.$vant.Button>
            )
        }
    }

    const confirm = (item) => {
        React.$vant.Dialog.confirm({
            title: '确认是否退出登陆？',
            onConfirm: async () => {
                let result = await React.$api.leaseConfirm({
                    busid: LoginUser.id,
                    leaseid: item.id
                })
                if (result.code == 1) {
                    getLeaseIndexData();
                    React.$vant.Toast.success({
                        message: result.msg,
                    })
                } else {
                    React.$vant.Toast.fail(result.msg)
                }
            },
        }).catch(err => { });
    }


    return (
        <>
            <React.$vant.NavBar
                title="我的订单"
                leftText=""
                onClickLeft={() => navigate('/business/my')}
            />
            <React.$vant.Tabs active={status} sticky onClickTab={(val) => setStatus(val.name)}>
                {statuslist.map(item => (
                    <React.$vant.Tabs.TabPane key={item.id} title={item.name} name={item.id}>
                    </React.$vant.Tabs.TabPane>
                ))}
            </React.$vant.Tabs>

            {
                list.map((item) => {
                    return (
                        <React.$vant.ProductCard
                            key={item.id}
                            price={item.price}
                            title={item.product.name}
                            thumb={item.product.thumb_text}
                            tags={
                                <>
                                    <div style={{ marginTop: '5px' }}>
                                        押金： {item.rent}
                                    </div>
                                    <div style={{ marginTop: '5px' }}>
                                        日租：{item.product.rent_price}
                                    </div>
                                </>
                            }
                            num={
                                <>
                                    <React.$vant.Tag type="danger" size="medium" round >
                                        {item.status_text}
                                    </React.$vant.Tag>
                                </>
                            }
                            footer={
                                <>
                                    <React.$vant.Button size="mini" type="primary" round plain style={{ marginRight: 2, marginTop: '10px' }} onClick={() => navigate(`/product/leaseinfo?leaseid=${item.id}`)}>
                                        订单详情
                                    </React.$vant.Button>

                                    <Btns item={item} />

                                    {
                                        item.expcode &&
                                        <React.$vant.Button size="mini" type="danger" round plain>
                                            物流详情
                                        </React.$vant.Button>
                                    }

                                </>
                            }
                        />
                    )
                })
            }
        </>
    )
}

export default LeaseIndex;