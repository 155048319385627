import { POST, UPLOAD } from '@/services/request'

export default {
    leaseAdd(data = {}) {
        return UPLOAD({
            url: '/lease/add',
            params: data
        })
    },
    leaseIndex(data = {}) {
        return POST({
            url: '/lease/index',
            params: data
        })
    },
    leaseInfo(data = {}) {
        return POST({
            url: '/lease/info',
            params: data
        })
    },
    leaseConfirm(data = {}) {
        return POST({
            url: '/lease/confirm',
            params: data
        })
    },
    leaseExpresslist(data = {}) {
        return POST({
            url: '/lease/expresslist',
            params: data
        })
    },
    leaseRecovery(data = {}) {
        return POST({
            url: '/lease/recovery',
            params: data
        })
    },
    leaseComment(data = {}) {
        return POST({
            url: '/lease/comment',
            params: data
        })
    },
    leaseRecord(data = {}) {
        return POST({
            url: '/lease/record',
            params: data
        })
    },
}