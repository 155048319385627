import Home from '@/components/home'
import Login from '@/components/business/login'
import AuthRouter from './auth'
import { Routes, Route, Outlet } from 'react-router-dom'

const ModulesFile = require.context('./', true, /index.js$/)

//接口集合
var RouterMap = []

ModulesFile.keys().reduce((modules, modulePath) => {
    //文件名
    const ModuleName = modulePath.replace(/^.\/(.*)\.js/, '$1')

    //不包含当前index.js 文件
    if (ModuleName !== 'index') {
        //请求列表
        const ModuleList = ModulesFile(modulePath)

        //追加到数组
        RouterMap.push(...ModuleList.default)
    }

    return RouterMap
}, {})

const RouterList = () => {
    return (
        <>
        {/* service */}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/" element={<Outlet />}>
                {
                        RouterMap.map((item, index) => {
                            return (
                                <Route key={index} path={item.path} element={<AuthRouter auth={item.auth} component={<item.component/>}></AuthRouter>}>
                                    {
                                        item.children && item.children.map((child, idx) =>{
                                            return (
                                                <Route key={idx} path={child.path} element={<AuthRouter auth={child.auth} component={<child.component/>}></AuthRouter>} />
                                            )
                                        })
                                    }
                                </Route>
                            )
                        })
                    }

                </Route>
            </Routes>
          
          
        </>
    )
}

export default RouterList;